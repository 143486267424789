/* eslint-disable no-underscore-dangle */
import STATUS from './globalStatuses';
import { defaultCartResourceValues } from '../modules/new-account/routing-wrappers/non-degree/nonDegreeHelper';

const statusTemplate = {
  getStatus: STATUS.UNFETCHED,
  modifyStatus: STATUS.UNFETCHED,
  errorData: null,
};

export default {
  csrf: {
    status: STATUS.UNFETCHED,
  },
  wallet: {
    last4Ssn: '',
    ssnStatus: statusTemplate,
  },
  person: {
    person: {},
    personETag: '',
    personStatus: statusTemplate,
    multistepCreateAccountStatus: statusTemplate,
  },
  personMeta: {
    meta: {},
    personMetaStatus: statusTemplate,
  },
  personInformation: {
    information: {},
    informationETag: '',
    personInformationStatus: statusTemplate,
  },
  personCitizenship: {
    citizenship: {},
    citizenshipETag: '',
    personCitizenshipStatus: statusTemplate,
  },
  personAssociations: {
    associations: [],
    personAssociationStatus: statusTemplate,
  },
  personMilitary: {
    military: {},
    militaryETag: '',
    personMilitaryStatus: statusTemplate,
  },
  personName: {
    name: {},
    nameETag: '',
    personNameStatus: statusTemplate,
  },
  personDestiny: {
    areDependenciesFulfilled: false,
    personDestinyStatus: statusTemplate,
  },
  contactPhone: {
    phone: [{}],
    contactPhoneStatus: statusTemplate,
  },
  contactEmail: {
    email: {},
    contactEmailStatus: statusTemplate,
  },
  contactAddress: {
    address: {},
    contactAddressStatus: statusTemplate,
  },
  contactConsent: {
    phoneConsent: {},
    indianaConsent: {},
    emailConsent: {},
    phoneConsentStatus: statusTemplate,
    emailConsentStatus: statusTemplate,
    indianaConsentStatus: statusTemplate,
  },
  user: {
    userStatus: statusTemplate,
  },
  cas: {
    casStatus: statusTemplate,
  },
  saga: {
    workflow: [],
    workflowStatus: statusTemplate,
    detailedWorkflowStatus: {
      PERSONAL_INFORMATION: statusTemplate,
      BACKGROUND: statusTemplate,
      REVIEW: statusTemplate,
    },
    personTasks: {},
    personTasksStatus: statusTemplate,
  },
  cartResources: defaultCartResourceValues,
  enumeration: {
    // Adding US as the default country until data loads from service
    countries: [{
      id: '77b4f2fb-f7e6-4e00-852c-57d708dcebda',
      resource: 'Address',
      type: 'Country',
      code: 'US',
      name: 'United States',
      parentId: null,
      metadata: {
        phoneCode: '1',
        hasPostalCode: 'true',
        hasStateProvinceOrRegion: 'true',
        validAreaCodes: [],
      },
    }],
    countriesStatus: statusTemplate,
    statesUS: [],
    statesUSStatus: statusTemplate,
    provincesCanada: [],
    provincesMexico: [],
    provincesStatus: statusTemplate,
    militaryCountries: [],
    militaryCountriesStatus: statusTemplate,
    militaryStates: [],
    militaryStatesStatus: statusTemplate,
    militaryCities: [],
    militaryCitiesStatus: statusTemplate,
    districtsUS: [],
    districtsStatus: statusTemplate,
    outlyingAreas: [],
    outlyingAreasStatus: statusTemplate,
  },
  forgerock: {
    forgerockStatus: statusTemplate,
  },
};
