import { FLOPFLIP_STATE_SLICE, createFlopflipReducer } from '@flopflip/react-redux';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
// import { createLogger } from 'redux-logger';
import { loadUser, reducer as oidc } from 'redux-oidc';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createThunkMiddleware from 'redux-thunk';
import defaultFlags from './defaultFeatureFlags';
import wallet from './domains/wallet/reducer';
import Services from '../services/index';
import person from './domains/person/reducer';
import personMeta from './domains/person/metadata/reducer';
import personInformation from './domains/person/information/reducer';
import personCitizenship from './domains/person/citizenship/reducer';
import personAssociations from './domains/person/association/reducer';
import personMilitary from './domains/person/military/reducer';
import personName from './domains/person/name/reducer';
import personDestiny from './domains/destiny/reducer';
import contactAddress from './domains/contact/address/reducer';
import contactEmail from './domains/contact/email/reducer';
import contactPhone from './domains/contact/phone/reducer';
import contactConsent from './domains/contact/consent/reducer';
import user from './domains/iam/reducer';
import cas from './domains/cas/reducer';
import saga from './domains/saga/reducer';
import cartResources from './domains/cart-resources/reducer';
import enumeration from './domains/enumeration/reducer';
import preloadedState from './preloadedState';
import userManager from './domains/oauth/userManager';
import csrf from './domains/csrf/reducer';
import forgerock from './domains/forgerock/reducer';

// Do not use window.__ENV
export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

const thunkWithServices = createThunkMiddleware.withExtraArgument({ Services });

const middlewares = [routerMiddleware(history), thunkWithServices];

// Do not use window.__ENV
// if (process.env.NODE_ENV === 'development') middlewares.push(createLogger());

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    cartResources,
    oidc,
    wallet,
    person,
    personName,
    personMeta,
    personInformation,
    personCitizenship,
    personAssociations,
    personMilitary,
    personDestiny,
    contactPhone,
    contactConsent,
    contactEmail,
    contactAddress,
    user,
    cas,
    saga,
    enumeration,
    csrf,
    forgerock,
    [FLOPFLIP_STATE_SLICE]: createFlopflipReducer(defaultFlags),
  }),
  preloadedState,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

loadUser(store, userManager);

export default store;
